import { createAction } from 'redux-actions';

export const constants = {
	setAppConfig: 'app.set_app_config',
	setReactBuildNumber: 'app.set_react_build_number',
	setServerIsUpdating: 'app.set_server_is_updating',
	setServerMessageReceived: 'app.set_server_message_received',
};

export const actions = Object.keys(constants).reduce(
	(res, key) => Object.assign(res, { [key]: createAction(constants[key]) }),
	{}
);
