import { handleActions } from 'redux-actions';
import { constants } from './actions';

export const initialState = {
	config: null,
	serverParams: {},
	serverMessage: null,
};

const handleSetAppConfig = (state, { payload }) => ({
	...state,
	config: payload,
});

const handleSetBuildNumber = (state, { payload }) => {
	const { serverParams } = state;
	const { buildNumber } = payload;
	return {
		...state,
		serverParams: {
			...serverParams,
			reactBuildNumberUpdate: buildNumber,
		},
	};
};

const handleServerMessage = (state, { payload }) => ({
	...state,
	serverMessage: payload,
});

const handleSetServerIsUpdating = (state, { payload }) => {
	const { serverParams } = state;
	const { serverUpdating } = payload;
	return {
		...state,
		serverParams: {
			...serverParams,
			serverUpdating,
		},
	};
};

export default handleActions(
	{
		[constants.setAppConfig]: handleSetAppConfig,
		[constants.setReactBuildNumber]: handleSetBuildNumber,
		[constants.setServerIsUpdating]: handleSetServerIsUpdating,
		[constants.setServerMessageReceived]: handleServerMessage,
	},
	initialState
);
