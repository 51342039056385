import { createStore, applyMiddleware, combineReducers } from 'redux';
import _ from 'lodash';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import { connectRoutes } from 'redux-first-router';
import createSagaMiddleware from 'redux-saga';
import restoreScroll from 'redux-first-router-restore-scroll';
import logger from 'redux-logger';
import { createRouterHooks } from '../../routing/options';

export default (props = {}) => {
	const {
		cableConnection,
		apolloClient,
		initialState,
		reducers,
		routesMap,
		rootSaga,
	} = props;

	const { reducer, middleware, enhancer } = connectRoutes(routesMap, {
		restoreScroll: restoreScroll(),
		...createRouterHooks(cableConnection),
	});

	const sagaMiddleware = createSagaMiddleware({
		context: {
			apolloClient,
		},
	});
	let rootReducerRef = { ...reducers, location: reducer };
	const rootReducer = combineReducers(rootReducerRef);
	const middlewares = [ReduxThunk, sagaMiddleware, middleware];

	if (_.get(initialState, 'app.serverParams.railsEnv') === 'development') {
		middlewares.push(logger);
	}

	const enhancers = composeWithDevTools(
		enhancer,
		applyMiddleware(...middlewares)
	);
	const store = createStore(rootReducer, initialState, enhancers);
	// const store =  configureStore({ reducer: rootReducer, preloadedState: initialState, enhancers });

	const clearRoutesMap = () => {
		_.keys(routesMap).forEach((key) => {
			delete routesMap[key];
		});
	};

	const addRoutesToRoutesMap = (newRoutes) => {
		_.forIn(newRoutes, (value, key) => {
			routesMap[key] = value;
		});
	};

	store.updateReducersAndRoutesMap = ({ newRoutesMap, newReducers }) => {
		clearRoutesMap();
		addRoutesToRoutesMap(newRoutesMap);

		rootReducerRef = { ...newReducers, location: reducer };
		const newRootReducer = combineReducers(rootReducerRef);
		store.replaceReducer(newRootReducer);
	};

	sagaMiddleware.run(rootSaga);

	store.getRoutesMap = () => routesMap;

	store.getRootReducer = () => rootReducerRef;

	return store;
};
